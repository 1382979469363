import axios from "axios";
import { WakeupDataGenerated as Base } from "./autogeneratedAPI";
import { post } from "./migrationApiService";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL // 'https://wud-connect-dev-ase-api.azurewebsites.net/api/v2'
export const CHANNABLE_BASE_URL = process.env.REACT_APP_CHANNABLE_URL

export const endpoints = {
    auth: () => BASE_URL + '/Identity/Authenticate',
    listProjectIdentifiers: (filter: "All"| "Ready" | "InProgress"| "NotReady"| "ClientComplete" | "PartnerComplete" | "Blocked" | "MigratedOnly" | "Ignored") => BASE_URL + `/Migration/GetProjectIdentifiersView/${filter}`,
    getProjectDetails: () => BASE_URL + '/Migration/GetProject',
    updateOwnerState: () => BASE_URL + '/Migration/UpdateOwnerState',
    updateProjectState: () => BASE_URL + '/Migration/UpdateProjectState',
    linkWithChannable: () => BASE_URL + '/Migration/LinkWithChannable',
}
export class WakeupDataConnectApiClient extends Base.WakeupDataConnectApiClientBase {

    async auth(username: string, password: string): Promise<{ data: Object, error: any }> {
        try {
            const data = await axios.post(endpoints.auth(), { username, password })
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error?.response ? error?.response.data.statusMessage : error.message, status: error.status } }
        }
    }

    async listProjectIdentifiers(token: string, filter: "All"| "Ready" | "InProgress"| "NotReady"| "ClientComplete" | "PartnerComplete" | "Blocked" | "MigratedOnly" | "Ignored"): Promise<{ data: Object, error: any }> {
        try {
            const data = await post(endpoints.listProjectIdentifiers(filter), token, {})
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async getProjectDetails(entityGuid: string, token: string): Promise<{ data: Object, error: any }> {
        try {
            const data = await post(endpoints.getProjectDetails(), token, { entityGuid })
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async updateOwnerPartnerState(userGuid: string, state: string, progress: string, token: string): Promise<{ data: Object, error: any }> {
      try {
          const data = await post(endpoints.updateOwnerState(), token, { userGuid, state, progress })
          return { data, error: null }
      } catch (error: any) {
          console.error('Error fetching data:', error);
          return { data: {}, error: { message: error.message, status: error.status } }
      }
  }

  async updateOwnerClientState(userGuid: string, state: string, progress: string, detachFromPartner: boolean, token: string): Promise<{ data: Object, error: any }> {
    try {
        const data = await post(endpoints.updateOwnerState(), token, { userGuid, state, progress, detachFromPartner })
        return { data, error: null }
    } catch (error: any) {
        console.error('Error fetching data:', error);
        return { data: {}, error: { message: error.message, status: error.status } }
    }
}

  async updateProjectState(projectGuid: string, progress: string, message: string, token: string): Promise<{ data: Object, error: any }> {
    try {
        const data = await post(endpoints.updateProjectState(), token, { projectGuid, progress, message })
        return { data, error: null }
    } catch (error: any) {
        console.error('Error fetching data:', error);
        return { data: {}, error: { message: error.message, status: error.status } }
    }
}

async linkWithChannable(projectGuid: string, channableUrl: string, token: string): Promise<{ data: Object, error: any }> {
  try {
      const data = await post(endpoints.linkWithChannable(), token, { projectGuid, channableUrl })
      return { data, error: null }
  } catch (error: any) {
      console.error('Error fetching data:', error);
      return { data: {}, error: { message: error.message, status: error.status } }
  }
}

}

export interface ProjectListItem extends Base.IProjectListItem {

}

export const migrationAPI = new WakeupDataConnectApiClient()