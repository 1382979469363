import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { styled as styledComponents } from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "../button/Button";
import { ClipboardIcon } from "../icons/Icons";
import { Collapse } from "@mui/material";
import { Label } from "../text/Text";
import MappingsTable from "./MappingsTable";
import { ImportStep, ImportStepValue, Mapping } from "@/interfaces/types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import JsonDisplay from "./ObjectDisplay";

const StyledTableCell: any = styled(TableCell)(
  ({ cursor }: { cursor?: string }) => ({
    padding: "1px 1rem",
    cursor: cursor,
    textDecoration: cursor === "pointer" ? "underline" : "none",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--secondary-lighter)",
      color: "var(--main)",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: "500",
      color: "var(--main)",
    },
    "&:hover": {
      color: "var(--secondary)",
    },
  })
);

const StyledTableRow = styled(TableRow)(({ open }: { open?: boolean }) => ({
  backgroundColor: open === true ? "var(--white)" : "var(--secondary-lighter)",
  "&:nth-of-type(odd)": {
    backgroundColor: open === true ? "var(--white)" : "var(--secondary-table)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "var(--white)",
  },
}));

const ButtonRowContainer = styledComponents.div`
  padding: 0.25rem 0;
  display: flex;
`;

const ExportToChannable = styledComponents.button`
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--grey);
  cursor: pointer;
  padding: 0 0.75rem;
  margin: 0.25rem 0.25rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ChannableLogo = styledComponents.img`
  max-height: 2rem;
  cursor: pointer;
`;

const DetailsContainer = styledComponents.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

function Row(props: {
  row: ImportStepValue;
  onExportMappings: (mapping: Mapping[]) => void;
}) {
  const { row, onExportMappings } = props;
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState("");
  const [details, setDetails] = useState<any>(row);
  const [selectedRowsMappings, setSelectedRowsMappings] = useState<string[]>(
    row.mappings.map((exportChannel) => exportChannel.importField)
  );

  const handleExportMappings = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mappings: Mapping[]
  ) => {
    event.stopPropagation();
    const selectedMappings = mappings.filter((mapping) =>
      selectedRowsMappings.includes(mapping.importField)
    );
    onExportMappings(selectedMappings ?? []);
  };

  return (
    <React.Fragment>
      <StyledTableRow open={open}>
        <StyledTableCell component="th" scope="row" sx={{ width: "10%" }}>
          {row.title}
        </StyledTableCell>
        <StyledTableCell
          sx={{ width: "10%" }}
          cursor={`${row.data ? "pointer" : "default"}`}
        >
          <ButtonRowContainer>
            {row.data ? (
              <Button
                $buttonType={
                  open && option === "Type"
                    ? "transparentSecondary"
                    : "transparent"
                }
                text={`${row.stepType} (${row.parser?.type ?? ""}${
                  row.receiver?.receiverType
                    ? `, ${row.receiver?.receiverType}`
                    : ""
                })`}
                $buttonSize="small"
                onClick={() => {
                  setOpen(open && option === "Type" ? false : true);
                  setDetails(row?.data);
                  setOption("Type");
                }}
              />
            ) : (
              `${row.stepType} (${row.parser?.type ?? ""}${
                row.receiver?.receiverType
                  ? `, ${row.receiver?.receiverType}`
                  : ""
              })`
            )}
          </ButtonRowContainer>
        </StyledTableCell>
        <StyledTableCell sx={{ width: "10%" }} cursor={"pointer"}>
          {row.mappings ? (
            <ButtonRowContainer>
              <Button
                $buttonType={
                  open && option === "Mappings"
                    ? "transparentSecondary"
                    : "transparent"
                }
                text={`${
                  open && option === "Mappings" ? "Hide" : "See"
                } mappings (${row.mappings.length})`}
                $buttonSize="xsmall"
                onClick={() => {
                  setOpen(open && option === "Mappings" ? false : true);
                  setDetails(row.mappings);
                  setOption("Mappings");
                }}
              />
              <ExportToChannable
                style={{ textDecoration: "none" }}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleExportMappings(event, row.mappings)}
              >
                Migration data
                <ChannableLogo
                  alt="Channable icon"
                  src={require("../../assets/images/channable.png")}
                />
              </ExportToChannable>
            </ButtonRowContainer>
          ) : (
            "-"
          )}
        </StyledTableCell>
        <StyledTableCell
          sx={{ width: "10%" }}
          cursor={row.merge ? "pointer" : ""}
        >
          {row.merge ? (
            <ButtonRowContainer>
              <Button
                $buttonType={
                  open && option === "Merge"
                    ? "transparentSecondary"
                    : "transparent"
                }
                text={`${row.merge.insertMethod} - ${row.merge.pairingType}`}
                onClick={() => {
                  setOpen(open && option === "Merge" ? false : true);
                  setDetails(row.merge);
                  setOption("Merge");
                }}
                $buttonSize="xsmall"
              />
            </ButtonRowContainer>
          ) : (
            <Label fontWeight="500" fontSize={0.9} color="var(--grey-dark)">
              None
            </Label>
          )}
        </StyledTableCell>
      </StyledTableRow>
      {open && (
        <TableRow style={{ backgroundColor: "var(--warning-lighter2)" }}>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {option === "Mappings" && (
                <MappingsTable
                  data={details}
                  setSelectedRows={setSelectedRowsMappings}
                  selectedRows={selectedRowsMappings}
                />
              )}
              {option === "Merge" && (
                <DetailsContainer>
                  {details?.mergeMatchFieldPrevious && (
                    <Label
                      fontSize={0.75}
                      fontWeight="500"
                    >{`Previous match field: ${details?.mergeMatchFieldPrevious}`}</Label>
                  )}
                  {details?.mergeMatchFieldCurrent && (
                    <Label
                      fontSize={0.75}
                      fontWeight="500"
                    >{`Current match field: ${details?.mergeMatchFieldCurrent}`}</Label>
                  )}
                  {details?.mergeExpressionPrevious && (
                    <Label
                      fontSize={0.75}
                      fontWeight="500"
                    >{`Previous expression: ${details?.mergeExpressionPrevious?.expression}`}</Label>
                  )}
                  {details?.mergeExpressionCurrent && (
                    <Label
                      fontSize={0.75}
                      fontWeight="500"
                    >{`Current expression: ${details?.mergeExpressionCurrent?.expression}`}</Label>
                  )}
                </DetailsContainer>
              )}
              {option === "More information" && (
                <DetailsContainer>
                  <Label fontSize={1.5} color="var(--secondary)">
                    <Label fontSize={0.75} fontWeight="500">
                      Copy JSON to clipboard{" "}
                      <ClipboardIcon
                        $size={0.75}
                        cursor={"pointer"}
                        onClick={() =>
                          navigator.clipboard.writeText(
                            JSON.stringify(details, null, 2)
                          )
                        }
                      />{" "}
                    </Label>
                  </Label>
                  <SyntaxHighlighter language="json" style={coy}>
                    {JSON.stringify(details, null, 2)}
                  </SyntaxHighlighter>
                </DetailsContainer>
              )}
              {option === "Type" && details && <JsonDisplay data={details} />}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

interface IImportSteps {
  data: ImportStep[];
  setSelectedMappings: (mapping: Mapping[]) => void;
}

export default function CollapsibleTable({
  data,
  setSelectedMappings,
}: IImportSteps) {
  const mappedData = (data: ImportStep[]) => {
    return data.map((step: ImportStep, index) => {
      const { value, warnings } = step;
      return { ...value };
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Mappings</StyledTableCell>
            <StyledTableCell>Merge</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappedData(data).map((row) => (
            <Row
              key={row.guid}
              row={row}
              onExportMappings={setSelectedMappings}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
