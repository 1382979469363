import React, {
  useEffect,
  useState,
  PointerEvent,
  useRef,
  useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Board from "@/components/board/Board";
import {
  AccordionContainer,
  AccordionItemTitle,
  ChannableIcon,
  ChannableLinkImage,
  ChannableLogo,
  CustomAccordionSummary,
  CustomLink,
  ExportToChannable,
  LoadingBox,
  ManageProjectState,
  MigrationMessage,
  ModalContent,
  ProjectContainer,
  ProjectDetails,
  ProjectHeader,
  ProjectTitle,
  WarningTreeItem,
} from "./ProjectStyles";
import { Accordion } from "@/components/accordion/Accordion";
import { AccordionDetails } from "@/components/accordion/AccordionDetails";
import { Label, Span } from "@/components/text/Text";
import {
  ChevronDownIcon,
  ClipboardIcon,
  CloseIcon,
  GearIcon,
  NewTabIcon,
  NoteIcon,
  PendingIcon,
} from "@/components/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import ImportSteps from "../importSteps/ImportSteps";
import Channels from "../channels/Channels";
import ChannableProjectInput from "./ChannableProjectInput";
import ModalLayout from "@/components/modal/ModalLayout";
import { ModalContainer, ModalHeader } from "./ChannableProjectInputStyles";
import { ModalContainer as ModalContainerBase } from "@/components/modal/ModalStyle";
import { Button } from "@/components/button/Button";
import { BASE_URL, CHANNABLE_BASE_URL, migrationAPI } from "@/api/migrationAPI";
import { setCurrentProjectDetails } from "@/store/coreSlice";
import { useSessionContext } from "@/contexts/SessionContext";
import { clearMigrationSession } from "@/store/migrationSessionSlice";
import { migrationRoutes } from "@/routes/Routes";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectFieldsTable from "@/components/table/ProjectFieldsTable";
import FieldConversionsTable from "@/components/table/FieldConversionsTable";
import {
  Export,
  ExportValue,
  IGroupedWarnings,
  Mapping,
} from "@/interfaces/types";
import Badge from "@/components/badge/Badge";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { warningComment } from "@/containers/project/WarningComment";
import JsonDisplay from "@/components/table/ObjectDisplay";
import ExportMappingsTable from "@/containers/project/ExportMapping";
import IndividualExport from "@/containers/project/IndividualExport";

interface IProject {}

export default function Project({}: IProject) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session } = useSessionContext();
  const { projectId } = useParams();
  const { accessToken } = session.data;
  const { currentProjectDetails } = useSelector(
    (state: RootState) => state.core
  );
  const [warningsExpanded, setWarningsExpanded] = useState<boolean>(true);
  const [feedExplanationExpanded, setFeedExplanationExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedMappings, setCheckedMappings] = useState(false);
  const [checkedExportMappings, setCheckedExportMappings] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>(
    currentProjectDetails?.value?.fieldConversions?.map(
      (step) => step.value.guid
    ) ?? []
  );
  const [selectedRowsExportMappings, setSelectedRowsExportMappings] = useState<
    string[]
  >(
    currentProjectDetails?.value?.exports?.map(
      (exportChannel) => exportChannel?.value?.guid
    ) ?? []
  );
  const [selectedMappings, setSelectedMappings] = useState<Mapping[]>([]);

  const [projectState, setProjectState] = useState("");
  const [manageProjectVisible, setManageProjectVisible] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");
  const [selectedChannel, setSelectedChannel] = useState<ExportValue>();
  const accordionExportContentRef = useRef<HTMLDivElement | null>(null);

  const [projectLinkVisible, setProjectLinkVisible] = useState(false);
  const [channelLinkVisible, setChannelLinkVisible] = useState(false);
  const [temporaryChannableUrl, setTemporaryChannableUrl] = useState("");
  const [feedUrl, setFeedUrl] = useState('')
  const [errorLink, setErrorLink] = useState("");
  const [expanded, setExpanded] = useState<string>("groupedWarnings");
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [exportsToExport, setExportsToExports] = useState<string[]>([]);
  const [channelToCompare, setChannelToCompare] = useState('')

  const handleChannelToCompare = (url: string) => {
    setChannelToCompare(url)
    setChannelLinkVisible(true)
  }

  const handleToggle = (panel: string) => {
    if (expandedItems.includes(panel)) {
      // If the panel is already expanded, remove it from the array
      setExpandedItems(expandedItems.filter((p) => p !== panel));
    } else {
      // If the panel is not expanded, add it to the array
      setExpandedItems([...expandedItems, panel]);
    }
  };

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setChecked(false);
  };

  const handleIconClickMappings = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCheckedMappings(false);
  };

  const handleIconClickExportMappings = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCheckedExportMappings(false);
  };

  const handleWarningsChange = () => {
    setWarningsExpanded(!warningsExpanded);
  };
  expandedItems.includes("general");

  const handleFeedExplanationExpanded = () => {
    setFeedExplanationExpanded(!feedExplanationExpanded)
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : "");
    };

  const handleExportAllFieldConversions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setChecked(true);
  };

  const handleExportAllExportMappings = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    channel: ExportValue,
    values: Array<string>
  ) => {
    event.stopPropagation();
    setExportsToExports(values);
    await setSelectedChannel(channel);
    setCheckedExportMappings(true);
  };

  const getProjectDetails = (entityGuid: string) => {
    setLoading(true);
    migrationAPI
      .getProjectDetails(entityGuid, accessToken)
      .then((response: any) => {
        const { data, error } = response;
        setLoading(false);
        if (!error) {
          dispatch(setCurrentProjectDetails(data?.value));
        } else {
          dispatch(clearMigrationSession());
          navigate(migrationRoutes.login());
        }
      });
  };

  const countTotalMessages = (warnings: IGroupedWarnings[]): number => {
    return warnings.reduce(
      (total, warning) => total + warning.messages.length,
      0
    );
  };

  const getBadgeColor = (typeId: number): string => {
    const colors: Record<number, string> = {
      0: "var(--note)",
      1: "var(--medium)",
      2: "var(--high)",
      3: "var(--critical)",
    };
    return colors[typeId];
  };

  const exportFieldConversions = () => {
    const fieldsFiltered = currentProjectDetails?.value?.fieldConversions
      .map((field) => field.value)
      .filter((field) => selectedRows.includes(field.guid));
    const exportJSON = JSON.stringify(
      {
        fieldConversions: fieldsFiltered,
        coreFields: currentProjectDetails?.value?.coreFields,
      },
      null,
      2
    );
    return exportJSON;
  };

  const exportChannelFieldConversions = () => {
    const fieldConversions =
      selectedChannel?.exportFieldConversions?.fieldConversions.filter(
        (field) => exportsToExport.includes(field.coreField)
      );
    const coreFields = selectedChannel?.exportFieldConversions?.coreFields;
    const exportJSON = JSON.stringify(
      {
        fieldConversions: fieldConversions,
        coreFields: {
          ...currentProjectDetails?.value?.coreFields,
          ...coreFields,
        },
      },
      null,
      2
    );
    return exportJSON;
  };

  const exportMappings = () => {
    const exportJSON = JSON.stringify(
      {
        mappings: selectedMappings,
        coreFields: currentProjectDetails?.value?.coreFields,
      },
      null,
      2
    );
    return exportJSON;
  };

  const onSelectMappings = (mapping: Mapping[]) => {
    setSelectedMappings(mapping);
    setCheckedMappings(true);
  };

  useEffect(() => {
    const guid = projectId;
    if (guid) {
      getProjectDetails(guid);
      setExpanded("groupedWarnings");
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProjectDetails?.value?.guid === projectId) {
      setSelectedRows(
        currentProjectDetails?.value?.fieldConversions?.map(
          (step) => step.value.guid
        ) ?? []
      );
      setSelectedRowsExportMappings(
        currentProjectDetails?.value?.exports?.map(
          (exportChannel) => exportChannel?.value?.guid
        ) ?? []
      );
      setTemporaryChannableUrl(currentProjectDetails.value?.channableUrl ?? "");
    }
  }, [currentProjectDetails]);

  const projectStates = [
    { name: "NotStarted", color: "var(--warning-light)" },
    { name: "InProgress", color: "var(--import)" },
    { name: "Blocked", color: "var(--accent-02-light)" },
    { name: "Migrated", color: "var(--green-light)" },
    { name: "Ignored", color: "var(--accent-02-light)" },
  ];

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProjectState(event.target.value as string);
  };

  const extractIdsFromUrl = (
    url: string
  ): { url: string; errorMessage: string } => {
    try {
      const pathSegments = new URL(url).pathname.split("/"); // Split the URL pathname by '/'

      // Extract IDs using known positions relative to 'companies' and 'projects'
      const companyId = pathSegments[pathSegments.indexOf("companies") + 1];
      const projectId = pathSegments[pathSegments.indexOf("projects") + 1];

      // Validate if both IDs are found
      if (!companyId && !projectId)
        return {
          url: "",
          errorMessage: "CompanyId and ProjectId are missing in the URL",
        };
      if (!projectId)
        return { url: "", errorMessage: "ProjectId is missing in the URL" };
      if (!companyId)
        return { url: "", errorMessage: "CompanyId is missing in the URL" };

      return {
        url: `${CHANNABLE_BASE_URL}/companies/${companyId}/projects/${projectId}/dashboard`,
        errorMessage: "",
      };
    } catch (error) {
      console.error("Invalid URL:", error);
      return {
        url: "",
        errorMessage: "Error parsing the URL. Try with another URL",
      };
    }
  };

  const updateProjectState = (
    guid: string,
    progress: string,
    message: string
  ) => {
    migrationAPI
      .updateProjectState(guid, progress, message, accessToken)
      .then((response: any) => {
        const { data, error } = response;
        if (!error) {
          setManageProjectVisible(false);
        } else {
          dispatch(clearMigrationSession());
          navigate(migrationRoutes.login());
        }
      });
  };

  const linkWithChannable = (guid: string, channableUrl: string) => {
    const { url, errorMessage } = extractIdsFromUrl(channableUrl);
    if (url) {
      migrationAPI
        .linkWithChannable(guid, url, accessToken)
        .then((response: any) => {
          const { data, error } = response;
          if (!error) {
            setErrorLink("");
            setProjectLinkVisible(false);
          } else {
            if (error.status === 500) {
              setErrorLink(error.message?.value);
            } else {
              dispatch(clearMigrationSession());
              navigate(migrationRoutes.login());
            }
          }
        });
    } else {
      setErrorLink(errorMessage);
    }
  };

  const handleCloseModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setManageProjectVisible(false);
  };

  const handleCloseLinkModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setErrorLink("");
    setProjectLinkVisible(false);
  };

  const handleCloseChannelLinkModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setErrorLink("");
    setChannelLinkVisible(false);
  };

  const setSelectedChannelExpanded = async (expandedId: string) => {
    await setExpanded(`export-content-${expandedId}`);
    await handleToggle(`export-content-${expandedId}`);
  };

  useEffect(() => {
    if (expanded.includes("export-content-")) {
      handleScrollToAccordion();
    }
  }, [expandedItems]);

  const handleScrollToAccordion = () => {
    if (accordionExportContentRef.current) {
      accordionExportContentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const goToFeedComparer = () => {
    const feedChannableUrl = `${channelToCompare}${feedUrl}`
    window.open(feedChannableUrl, '_blank', 'noopener,noreferrer');
    setChannelLinkVisible(false)
    setFeedUrl('')
    setChannelToCompare('')
  }

  useEffect(() => {
    if (projectState && !manageProjectVisible) {
      setProjectState("");
      if (projectId) getProjectDetails(projectId);
    }
  }, [manageProjectVisible]);

  useEffect(() => {
    if (temporaryChannableUrl && !projectLinkVisible) {
      setTemporaryChannableUrl("");
      if (projectId) getProjectDetails(projectId);
    }
  }, [projectLinkVisible]);

  return (
    <Board>
      {loading ? (
        <LoadingBox>
          <CircularProgress size={"5rem"} />
          <Label fontSize={2}>Preparing the data...</Label>
        </LoadingBox>
      ) : (
        <ProjectContainer>
          <ProjectHeader>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.25rem",
              }}
            >
              <ManageProjectState
                backgroundcolor={
                  projectStates.find(
                    (state) =>
                      state.name ===
                      currentProjectDetails?.value?.migration.progress
                  )?.color ?? "var(--warning-light)"
                }
                disabled={false}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  setManageProjectVisible(true);
                  setProjectState(
                    currentProjectDetails?.value?.migration.progress ?? ""
                  );
                  setUpdateMessage(
                    currentProjectDetails?.value?.migration.message ?? ""
                  );
                }}
              >
                <GearIcon color={"var(--main)"} />
                {currentProjectDetails?.value?.migration.progress
                  ? `${
                      currentProjectDetails?.value?.migration.progress
                    } (${new Date(
                      currentProjectDetails?.value?.migration.updated
                    )
                      .toISOString()
                      .slice(0, 10)} | ${
                      currentProjectDetails?.value?.migration.migrator
                    })`
                  : "Pending migration"}
              </ManageProjectState>
              <div
                style={{ marginLeft: "0.5rem", display: "flex", gap: "0.5rem" }}
              >
                {currentProjectDetails?.value?.connectUrl && (
                  <CustomLink
                    fontWeight="500"
                    to={currentProjectDetails?.value?.connectUrl}
                    target="_blank"
                  >
                    View in WakeupData <NewTabIcon $size={0.8} />{" "}
                  </CustomLink>
                )}
                <ChannableProjectInput
                  value={currentProjectDetails?.value?.channableUrl ?? ""}
                  setShowModal={setProjectLinkVisible}
                />
              </div>
            </div>
            <ProjectTitle fontSize={1.25}>
              {`${currentProjectDetails?.value?.partnerName} > ${currentProjectDetails?.value?.clientName} > ${currentProjectDetails?.value?.title}`}
            </ProjectTitle>
          </ProjectHeader>
          <div>
            <ProjectDetails>
              {currentProjectDetails?.value?.description && (
                <Label fontWeight="500">
                  {" "}
                  {currentProjectDetails?.value?.description}
                </Label>
              )}
              {currentProjectDetails?.value?.partnerUserGuid && (
                <Span fontSize={0.75}>
                  Partner guid:
                  <Span fontWeight="500" fontSize={0.75}>
                    {currentProjectDetails?.value?.partnerUserGuid}
                  </Span>{" "}
                  <ClipboardIcon
                    cursor={"pointer"}
                    $size={0.75}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        currentProjectDetails?.value?.partnerUserGuid ?? ""
                      )
                    }
                  />
                </Span>
              )}
              {currentProjectDetails?.value?.clientUserGuid && (
                <Span fontSize={0.75}>
                  Client guid:
                  <Span fontWeight="500" fontSize={0.75}>
                    {currentProjectDetails?.value?.clientUserGuid}
                  </Span>{" "}
                  <ClipboardIcon
                    cursor={"pointer"}
                    $size={0.75}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        currentProjectDetails?.value?.clientUserGuid ?? ""
                      )
                    }
                  />
                </Span>
              )}
              {currentProjectDetails?.value?.guid && (
                <Span fontSize={0.75}>
                  {" "}
                  Project guid:{" "}
                  <Span fontWeight="500" fontSize={0.75}>
                    {currentProjectDetails?.value?.guid}{" "}
                  </Span>
                  <ClipboardIcon
                    cursor={"pointer"}
                    $size={0.75}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        currentProjectDetails?.value?.guid ?? ""
                      )
                    }
                  />
                </Span>
              )}
            </ProjectDetails>
            {currentProjectDetails?.value?.migration.message && (
              <MigrationMessage backgroundcolor={"var(--secondary-lighter)"}>
                <Span fontSize={0.75}>
                  <NoteIcon style={{ marginRight: "0.25rem" }} />
                  Note from the migrator:
                  <Span
                    style={{ marginLeft: "0.25rem" }}
                    fontSize={0.75}
                    fontWeight="500"
                    fontStyle="italic"
                  >{`"${currentProjectDetails?.value?.migration.message}", ${currentProjectDetails?.value?.migration.migrator}`}</Span>
                </Span>
              </MigrationMessage>
            )}
            {/* GROUPED WARNINGS */}
            {currentProjectDetails?.groupedWarnings.filter(
              (groupedWarning) => groupedWarning.severity === "Critical"
            )?.length > 0 && (
              <AccordionContainer>
                <Accordion
                  expanded={warningsExpanded}
                  onChange={handleWarningsChange}
                  defaultExpanded={true}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="groupedWarnings-content"
                    id="groupedWarnings-header"
                    expanded={warningsExpanded}
                    customColor={"var(--error)"}
                  >
                    <AccordionItemTitle>
                      Warnings (
                      {countTotalMessages(
                        currentProjectDetails?.groupedWarnings.filter(
                          (groupedWarning) =>
                            groupedWarning.severity === "Critical"
                        )
                      )}
                      )
                    </AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <SimpleTreeView>
                      {currentProjectDetails?.groupedWarnings
                        ?.filter(
                          (groupedWarning) =>
                            groupedWarning.severity === "Critical"
                        )
                        .map((warning: IGroupedWarnings, index: number) => (
                          <TreeItem
                            style={{ padding: 0 }}
                            key={index}
                            itemId={`${warning.type}${index}`}
                            sx={{
                              "& .MuiTreeItem-content": {
                                alignItems: "flex-start",
                              },
                              "& .MuiTreeItem-content .MuiTreeItem-iconContainer svg":
                                {
                                  paddingTop: "0.5rem",
                                  fontSize: "1.25rem",
                                },
                            }}
                            label={
                              <div>
                                <Span
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    padding: "0.5rem 0 0 0",
                                  }}
                                  fontWeight="500"
                                >
                                  <Badge
                                    color="var(--white)"
                                    backgroundcolor={getBadgeColor(
                                      warning.severityId
                                    )}
                                    number={warning.messages.length}
                                    text={`${warning.messages.length} ${
                                      warning.messages.length > 1
                                        ? "advisories"
                                        : "advisory"
                                    } of severity type: ${
                                      warning.severity
                                    } in this project`}
                                  />{" "}
                                  <Label fontWeight="500">
                                    {warning.typeTitle}
                                  </Label>
                                </Span>
                                {warningComment(warning.typeTitle)}
                              </div>
                            }
                          >
                            {warning.messages.map((msg, index2) => (
                              <WarningTreeItem key={index2}>
                                <Label
                                  key={index2}
                                  fontWeight="500"
                                  fontSize={0.8}
                                  color="var(--main)"
                                  style={{ marginLeft: "1.75rem" }}
                                >
                                  • {msg}
                                </Label>
                              </WarningTreeItem>
                            ))}
                          </TreeItem>
                        ))}
                    </SimpleTreeView>
                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>
            )}

            {/* ADVISORIES */}
            {currentProjectDetails?.groupedWarnings.filter(
              (groupedWarning) => groupedWarning.severity !== "Critical"
            )?.length > 0 && (
              <AccordionContainer>
                <Accordion
                  expanded={warningsExpanded}
                  onChange={handleWarningsChange}
                  defaultExpanded={true}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="groupedWarnings-content"
                    id="groupedWarnings-header"
                    expanded={warningsExpanded}
                    customColor={"var(--warning-lighter2)"}
                  >
                    <AccordionItemTitle>
                      Advisories (
                      {countTotalMessages(
                        currentProjectDetails?.groupedWarnings.filter(
                          (groupedWarning) =>
                            groupedWarning.severity !== "Critical"
                        )
                      )}
                      )
                    </AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <SimpleTreeView>
                      {currentProjectDetails?.groupedWarnings
                        ?.filter(
                          (groupedWarning) =>
                            groupedWarning.severity !== "Critical"
                        )
                        .map((warning: IGroupedWarnings, index: number) => (
                          <TreeItem
                            style={{ padding: 0 }}
                            key={index}
                            itemId={`${warning.type}${index}`}
                            sx={{
                              "& .MuiTreeItem-content": {
                                alignItems: "flex-start",
                              },
                              "& .MuiTreeItem-content .MuiTreeItem-iconContainer svg":
                                {
                                  paddingTop: "0.5rem",
                                  fontSize: "1.25rem",
                                },
                            }}
                            label={
                              <div>
                                <Span
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    padding: "0.5rem 0",
                                  }}
                                  fontWeight="500"
                                >
                                  <Badge
                                    color="var(--white)"
                                    backgroundcolor={getBadgeColor(
                                      warning.severityId
                                    )}
                                    number={warning.messages.length}
                                    text={`${warning.messages.length} ${
                                      warning.messages.length > 1
                                        ? "advisories"
                                        : "advisory"
                                    } of severity type: ${
                                      warning.severity
                                    } in this project`}
                                  />
                                  {warning.typeTitle}
                                </Span>
                                {warningComment(warning.typeTitle)}
                              </div>
                            }
                          >
                            {warning.messages.map((msg, index2) => (
                              <WarningTreeItem key={index2}>
                                <Label
                                  key={index2}
                                  fontWeight="500"
                                  color="var(--main)"
                                  fontSize={0.8}
                                  style={{ marginLeft: "1.75rem" }}
                                >
                                  • {msg}
                                </Label>
                              </WarningTreeItem>
                            ))}
                          </TreeItem>
                        ))}
                    </SimpleTreeView>
                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>
            )}
            {/*GENERAL*/}
            {currentProjectDetails?.value?.general && (
              <AccordionContainer>
                <Accordion
                  expanded={expandedItems.includes("general")}
                  onChange={() => handleToggle("general")}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="groupedGeneral-content"
                    id="groupedGeneral-header"
                    expanded={expandedItems.includes("general")}
                  >
                    <AccordionItemTitle>General</AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <JsonDisplay data={currentProjectDetails?.value?.general} />
                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>
            )}
            {/* PROJECT FIELDS */}
            {currentProjectDetails?.value?.coreFields && (
              <AccordionContainer>
                <Accordion
                  expanded={expandedItems.includes("projectfields")}
                  onChange={() => handleToggle("projectfields")}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="projectfields-content"
                    id="projectfields-header"
                    expanded={expandedItems.includes("projectfields")}
                  >
                    <AccordionItemTitle>
                      Project fields (
                      {
                        Object.values(currentProjectDetails?.value?.coreFields)
                          .length
                      }
                      )
                    </AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <ProjectFieldsTable
                      data={Object.values(
                        Object.values(currentProjectDetails?.value?.coreFields)
                      ).map((field) => {
                        return { ...field };
                      })}
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>
            )}
            {/* IMPORT STEPS */}
            <AccordionContainer>
              <Accordion
                expanded={expandedItems.includes("import")}
                onChange={() => handleToggle("import")}
              >
                <CustomAccordionSummary
                  expandIcon={<ChevronDownIcon />}
                  aria-controls="import-content"
                  id="import-header"
                  expanded={expandedItems.includes("import")}
                >
                  <AccordionItemTitle>
                    Import steps (
                    {currentProjectDetails?.value?.importSteps.length})
                  </AccordionItemTitle>
                </CustomAccordionSummary>
                <AccordionDetails>
                  <ImportSteps
                    stepsList={currentProjectDetails?.value?.importSteps ?? []}
                    setSelectedMappings={onSelectMappings}
                  />
                </AccordionDetails>
              </Accordion>
            </AccordionContainer>
            {/* FIELD CONVERSIONS */}
            <AccordionContainer>
              <Accordion
                title={
                  currentProjectDetails?.value?.fieldConversions?.length &&
                  currentProjectDetails?.value?.fieldConversions?.length > 0
                    ? ""
                    : "No field conversions in this project"
                }
                expanded={expandedItems.includes("fieldConversions")}
                onChange={() => handleToggle("fieldConversions")}
                disabled={
                  currentProjectDetails?.value?.fieldConversions?.length
                    ? currentProjectDetails?.value?.fieldConversions?.length < 1
                    : true
                }
              >
                <CustomAccordionSummary
                  expandIcon={<ChevronDownIcon />}
                  aria-controls="fields-content"
                  id="fields-header"
                  expanded={expandedItems.includes("fieldConversions")}
                >
                  <AccordionItemTitle>
                    Field conversions (
                    {currentProjectDetails?.value?.fieldConversions?.length &&
                    currentProjectDetails?.value?.fieldConversions?.length > 0
                      ? currentProjectDetails?.value?.fieldConversions?.length
                      : "None"}
                    )
                  </AccordionItemTitle>
                  <ExportToChannable
                    disabled={selectedRows.length === 0}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleExportAllFieldConversions(event)}
                  >
                    {selectedRows.length === 0
                      ? "Migration data"
                      : selectedRows.length !==
                        currentProjectDetails?.value?.fieldConversions.length
                      ? `Migration data selected (${selectedRows.length})`
                      : "Migration data"}
                    <ChannableLogo
                      alt="Channable icon"
                      src={require("../../assets/images/channable.png")}
                    />{" "}
                  </ExportToChannable>
                </CustomAccordionSummary>
                <AccordionDetails>
                  <FieldConversionsTable
                    data={currentProjectDetails?.value?.fieldConversions ?? []}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                </AccordionDetails>
              </Accordion>
            </AccordionContainer>

            {/* EXPORTS */}
            {currentProjectDetails?.value?.exports.length ? (
              <AccordionContainer>
                <Accordion
                  expanded={expandedItems.includes("exports")}
                  onChange={() => handleToggle("exports")}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="exports-content"
                    id="exports-header"
                    expanded={expandedItems.includes("exports")}
                  >
                    <AccordionItemTitle>
                      Export channels (
                      {currentProjectDetails?.value?.exports.length})
                    </AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <Channels
                      channelsList={currentProjectDetails?.value?.exports ?? []}
                      setSelectedChannel={(expandedId) =>
                        setSelectedChannelExpanded(expandedId)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>
            ) : null}
            {/* EXPORT MAPPINGS */}
            {currentProjectDetails?.value?.exports.map(
              (exportChannel: Export, index: number) => {
                const { value, warnings } = exportChannel;
                return (
                  <IndividualExport
                    ref={
                      expandedItems.includes(`export-content-${value.guid}`)
                        ? accordionExportContentRef
                        : null
                    }
                    key={index}
                    isExpanded={expandedItems.includes(
                      `export-content-${value.guid}`
                    )}
                    toggle={() => handleToggle(`export-content-${value.guid}`)}
                    value={value}
                    handleExportAllExportMappings={
                      handleExportAllExportMappings
                    }
                    setChannelLink={handleChannelToCompare}
                  />
                );
              }
            )}
          </div>
          {/* ANY MODAL IN PROJECT VIEW */}
          {checked && (
            <ModalLayout onClick={handleIconClick}>
              <ModalContainer
                onClick={(event: PointerEvent<HTMLDivElement>) =>
                  event.stopPropagation()
                }
              >
                <ModalHeader>
                  <Label fontSize={1.25}>
                    EXPORT FIELD CONVERSIONS TO CHANNABLE
                  </Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleIconClick}
                    $size={1.5}
                    hover={"var(--secondary)"}
                  />
                </ModalHeader>
                <ModalContent>
                  <SyntaxHighlighter language="json" style={coy}>
                    {exportFieldConversions()}
                  </SyntaxHighlighter>
                </ModalContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    $buttonSize="small"
                    text="Copy to clipboard"
                    $buttonType="primary"
                    icon={<ClipboardIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(exportFieldConversions());
                      setChecked(false);
                    }}
                  />
                </div>
              </ModalContainer>
            </ModalLayout>
          )}
          {checkedMappings && (
            <ModalLayout onClick={handleIconClickMappings}>
              <ModalContainer
                onClick={(event: PointerEvent<HTMLDivElement>) =>
                  event.stopPropagation()
                }
              >
                <ModalHeader>
                  <Label fontSize={1.25}>EXPORT MAPPINGS</Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleIconClickMappings}
                    $size={1.5}
                    hover={"var(--secondary)"}
                  />
                </ModalHeader>
                <ModalContent>
                  <SyntaxHighlighter language="json" style={coy}>
                    {exportMappings()}
                  </SyntaxHighlighter>
                </ModalContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    $buttonSize="small"
                    text="Copy to clipboard"
                    $buttonType="primary"
                    icon={<ClipboardIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(exportMappings());
                      setChecked(false);
                    }}
                  />
                </div>
              </ModalContainer>
            </ModalLayout>
          )}
          {checkedExportMappings && (
            <ModalLayout onClick={handleIconClickExportMappings}>
              <ModalContainer
                onClick={(event: PointerEvent<HTMLDivElement>) =>
                  event.stopPropagation()
                }
              >
                <ModalHeader>
                  <Label fontSize={1.25}>EXPORT CHANNELS TO CHANNABLE</Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleIconClickExportMappings}
                    $size={1.5}
                    hover={"var(--secondary)"}
                  />
                </ModalHeader>
                <ModalContent>
                  <SyntaxHighlighter language="json" style={coy}>
                    {exportChannelFieldConversions()}
                  </SyntaxHighlighter>
                </ModalContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    $buttonSize="small"
                    text="Copy to clipboard"
                    $buttonType="primary"
                    icon={<ClipboardIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        exportChannelFieldConversions()
                      );
                      setCheckedExportMappings(false);
                    }}
                  />
                </div>
              </ModalContainer>
            </ModalLayout>
          )}
        </ProjectContainer>
      )}
      {manageProjectVisible && (
        <ModalLayout onClick={handleCloseModal}>
          <ModalContainerBase
            style={{
              padding: "1rem",
              maxWidth: "40%",
              minHeight: "15rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(event: PointerEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Label style={{ textAlign: "left" }}>
                    Update project state
                  </Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleCloseModal}
                    $size={1}
                    hover={"var(--secondary)"}
                  />
                </div>
                <Box sx={{ width: "50%", zIndex: 99999 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="project-simple-select-label">
                      Project state
                    </InputLabel>
                    <Select
                      labelId="project-simple-select-label"
                      id="project-simple-select"
                      value={projectState}
                      label="Project state"
                      onChange={handleChangeProject}
                      sx={{ textAlign: "left", color: "var(--main)" }}
                    >
                      {projectStates.map((state, index) => (
                        <MenuItem
                          key={index}
                          sx={{ color: "var(--main)" }}
                          value={state.name}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TextField
                  id="standard-basic"
                  label="Message"
                  variant="standard"
                  value={updateMessage}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateMessage(event.target.value);
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 0,
              }}
            >
              <Button
                $buttonSize="small"
                text="Update project"
                $buttonType="primary"
                onClick={() =>
                  updateProjectState(
                    currentProjectDetails?.value?.guid ?? "",
                    projectState,
                    updateMessage
                  )
                }
              />
            </div>
          </ModalContainerBase>
        </ModalLayout>
      )}
      {projectLinkVisible && (
        <ModalLayout onClick={handleCloseLinkModal}>
          <ModalContainerBase
            style={{
              padding: "1rem",
              maxWidth: "50%",
              minHeight: "15rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(event: PointerEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Label style={{ textAlign: "left" }}>
                    Link project with Channable
                  </Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleCloseLinkModal}
                    $size={1}
                    hover={"var(--secondary)"}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label
                    style={{
                      textAlign: "left",
                      display: "flex",
                      gap: "0.25rem",
                    }}
                    fontWeight="500"
                  >
                    Insert the link of the project dashboard from the Channable
                    app
                    <ChannableIcon
                      alt="Channable icon"
                      src={require("../../assets/images/channable.png")}
                    />
                  </Label>
                  <Label
                    style={{ textAlign: "left", marginTop: "1rem" }}
                    fontWeight="500"
                    fontStyle="italic"
                    fontSize={0.75}
                  >
                    It should look like this:
                  </Label>
                  <ChannableLinkImage
                    alt="Channable project link"
                    src={require("../../assets/images/projectlink.png")}
                  />
                </div>
                <TextField
                  id="standard-basic"
                  label="Channable project link"
                  variant="standard"
                  value={temporaryChannableUrl}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTemporaryChannableUrl(event.target.value);
                  }}
                />
                {errorLink && (
                  <Label
                    style={{ textAlign: "left", marginTop: "-1rem" }}
                    fontWeight="500"
                    fontSize={0.75}
                    color="var(--error)"
                  >
                    {errorLink}
                  </Label>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 0,
              }}
            >
              <Button
                $buttonSize="small"
                text="Update link"
                $buttonType="primary"
                onClick={() =>
                  linkWithChannable(
                    currentProjectDetails?.value?.guid ?? "",
                    temporaryChannableUrl
                  )
                }
              />
            </div>
          </ModalContainerBase>
        </ModalLayout>
      )}
      {channelLinkVisible && (
        <ModalLayout onClick={handleCloseChannelLinkModal}>
          <ModalContainerBase
            style={{
              padding: "1rem",
              maxWidth: "90%",
              minWidth: '75%',
              minHeight: feedExplanationExpanded ? "45rem" : '5rem',
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={(event: PointerEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Label style={{ textAlign: "left" }}>
                    Link feed with Channable
                  </Label>
                  <CloseIcon
                    cursor={"pointer"}
                    onClick={handleCloseChannelLinkModal}
                    $size={1}
                    hover={"var(--secondary)"}
                  />
                </div>
                <AccordionContainer style={{margin: 0}} >
                <Accordion
                  expanded={feedExplanationExpanded}
                  onChange={handleFeedExplanationExpanded}
                >
                  <CustomAccordionSummary
                    expandIcon={<ChevronDownIcon />}
                    aria-controls="feedExplanation-content"
                    id="feedExplanation-header"
                    expanded={feedExplanationExpanded}
                  >
                    <AccordionItemTitle>
                      How to get the feed url in Channable
                    </AccordionItemTitle>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                  <div style={{ display: "flex", flexDirection: "column", gap: '1rem', padding: '0.5rem 1rem' }}>
                <Label
                    style={{
                      textAlign: "left",
                    }}
                    fontWeight="500"
                  >
                    1. Ensure the <b>WakeupData migration</b> feature flag is enabled in the account
                  </Label>
                  <Label
                    style={{
                      textAlign: "left",
                    }}
                    fontWeight="500"
                  >
                    2. Go to <b>Settings</b> in the Feed page
                  </Label>
                  <Label
                    style={{
                      textAlign: "left",
                    }}
                    fontWeight="500"
                  >
                    3. Click the <b>Gear icon</b> and then <b>"WUD - Download inactive feed"</b>
                  </Label>
                  <img
                    style={{maxHeight: '10rem', maxWidth: '20rem'}}
                    alt="Channable feed link"
                    src={require("../../assets/images/downloadFeed.png")}
                  />
                  <Label
                    style={{
                      textAlign: "left",
                    }}
                    fontWeight="500"
                  >
                    4. When the feed has been downloaded, <b>right click</b> on the file and then <b>"Copy link address"</b>
                  </Label>
                  <img
                  style={{maxHeight: '20rem', maxWidth: '10rem'}}
                    alt="Channable feed link"
                    src={require("../../assets/images/getLink.png")}
                  />
                <Label
                    style={{
                      textAlign: "left",
                      display: "flex",
                      gap: "0.25rem",
                    }}
                    fontWeight="500"
                  >
                    5. Insert the link of the feed from the Channable app
                    <ChannableIcon
                      alt="Channable icon"
                      src={require("../../assets/images/channable.png")}
                    />
                  </Label>
                </div>

                  </AccordionDetails>
                </Accordion>
              </AccordionContainer>

              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: 0,
                gap: '2rem'
              }}
            >
                <TextField
                style={{flex: 2}}
                  id="standard-basic"
                  label="Channable feed link"
                  variant="standard"
                  value={feedUrl}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFeedUrl(event.target.value);
                  }}
                />
                {errorLink && (
                  <Label
                    style={{ textAlign: "left", marginTop: "-1rem" }}
                    fontWeight="500"
                    fontSize={0.75}
                    color="var(--error)"
                  >
                    {errorLink}
                  </Label>
                )}
              <Button
                $buttonSize="small"
                text="Go to Feed comparer"
                $buttonType="primary"
                onClick={() =>
                  goToFeedComparer()
                }
              />
            </div>
          </ModalContainerBase>
        </ModalLayout>
      )}
    </Board>
  );
}
